<template>
  <div class="contact-container">
    <div class="about-image">
      <img src="../../public/size-circle.png" />
    </div>
    <div class="contact-content">
      <h2>{{ contactTitle }}</h2>
      <p>{{ contactText }}</p>

      <div class="contact-content__buttons">
        <div class="contact-content__buttons--duo">
          <primary-button
            :text="buttonText01"
            path="mailto:contato.hosanabarcelos@gmail.com"
          >
            <ph-envelope-simple :size="18" />
          </primary-button>
          <primary-button
            :text="buttonText02"
            path="https://t.me/hosanabarcelos"
          >
            <ph-telegram-logo :size="18" />
          </primary-button>
        </div>
      </div>
    </div>
    <div class="footer">
      <!-- <h2>{{ followText01 }}
            <router-link class="link" to="/feedbacks">
                {{ followText02 }}
            </router-link>
            {{ followText03 }}
        </h2> -->
    </div>
    <div style="position: absolute; bottom: 0; margin-bottom: 54px">
      <a href="https://www.buymeacoffee.com/hosana" target="_blank">
        <img
          src="https://cdn.buymeacoffee.com/buttons/default-white.png"
          alt="Buy Me A Coffee"
          height="38"
          width="158"
          style="border-radius: 8px"
        />
      </a>
    </div>
  </div>
</template>

  <script>
import PrimaryButton from "@/components/PrimaryButton.vue";
import { PhTelegramLogo, PhEnvelopeSimple } from "@phosphor-icons/vue";
import { mapState } from "vuex";

export default {
  components: { PrimaryButton, PhTelegramLogo, PhEnvelopeSimple },
  computed: {
    ...mapState(["language"]),

    contactTitle() {
      return this.language
        ? "Vamos construir algo juntos?"
        : "Let's build something together?";
    },
    contactText() {
      return this.language
        ? "Se você tem algum projeto em mente, ou apenas quer bater um papo, sinta-se à vontade para me enviar uma mensagem."
        : "If you have a project in mind, or just want to chat, feel free to send me a message.";
    },
    buttonText01() {
      return this.language ? "Email" : "Email";
    },
    buttonText02() {
      return this.language ? "Telegram" : "Telegram";
    },
    footerText() {
      const email = "contato.hosanabarcelos@gmail.com";
      return this.language
        ? "Se preferir, entre em contato comigo por email: " + email
        : "If you prefer, contact me by email: contato.hosanabarcelos@gmail.com " +
            email;
    },
    followText01() {
      return this.language ? "Acompanhe" : "Follow";
    },
    followText02() {
      return this.language ? "aqui" : "here";
    },
    followText03() {
      return this.language
        ? "o que estão dizendo sobre meu trabalho!"
        : "what people are saying about my work!";
    },
  },
};
</script>

<style lang="scss" scoped>
h2 {
  font-size: 1.5rem;
}

p {
  font-size: 1rem;
}

a {
  border-bottom: 0 !important;
}
.contact-container {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  h2 {
    background: linear-gradient(180deg, #fff 0%, rgba(255, 255, 255, 0.7) 100%);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    -webkit-text-fill-color: transparent;
    display: block;
    font-size: 1.2rem;
    margin-top: 16px;
  }
}
.about-image {
  height: 431px;
  position: absolute;
  top: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  z-index: -2;
  margin-top: 110px;
}
.contact-content {
  background: rgba(255, 255, 255, 0.01);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 24px;
  box-shadow: inset 0 0 0 8px #ffffff08;
  padding: 50px;
  transition: 0.45s cubic-bezier(0.6, 0.6, 0, 1) background-color,
    0.45s cubic-bezier(0.6, 0.6, 0, 1) border-color;
  background: radial-gradient(
      71.86% 50% at 50% 0%,
      rgba(168, 127, 255, 0.04) 0%,
      rgba(168, 127, 255, 0) 100%
    ),
    rgba(4, 1, 21, 0.1);
  color: #ffffff;
  backdrop-filter: blur(15px);
  height: max-content;
  z-index: 9;
  width: 631px;

  &__buttons {
    display: flex;
    gap: 14px;
    align-items: flex-end;

    &--duo {
      display: flex;
      gap: 14px;
    }
  }

  h2 {
    margin-bottom: 8px;
  }

  p {
    margin-bottom: 24px;
  }
}

.footer {
  text-align: center;
}

.link {
  margin-left: 4px;
  margin-right: 4px;
}

@media screen and (max-width: 740px) {
  .contact-content {
    width: 90%;

    &__buttons {
      flex-direction: column;
      align-items: start;
    }
  }

  .about-image {
    margin-top: 74px;
    overflow: hidden;
  }

  h2 {
    font-size: 1rem;
  }

  p {
    font-size: 0.8rem;
  }

  .footer {
    padding: 28px;
  }
}
</style>
