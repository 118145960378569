<template>
  <div class="experience-container">
    <video
      id="background-video"
      autoplay
      loop
      muted
      poster="../../public/videos/encryption-bg.webm"
    >
      <source src="../../public/videos/encryption-bg.webm" type="video/webm" />
    </video>
    <h1>{{ title }}</h1>
    <h4>{{ description }}</h4>
    <div class="experience-content">
      <ul class="timeline">
        <li
          v-for="(experience, index) in translatedExperiences"
          :key="index"
          class="timeline__entry"
        >
          <span class="timeline__id"></span>
          <div
            class="timeline__content"
            :class="{ 'timeline__content--flipped': index % 2 !== 0 }"
          >
            <div class="timeline__header-card">
              <h2 class="timeline__heading">{{ experience.jobTitle }}</h2>
              <!-- <img :src="experience.image" :class="{ 'solution-image': index === 0, 'cma-image': index === 2}" /> -->
            </div>
            <h6>{{ experience.date }}</h6>
            <p class="timeline__text">{{ experience.description }}</p>
            <div class="badges-container">
              <badge
                v-for="(badge, badgeIndex) in experience.badges"
                :key="badgeIndex"
                :text="badge"
              />
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

  <script>
import Badge from "@/components/Badge.vue";
import { mapState } from "vuex";
import { translations } from "@/utils/recomendations";

export default {
  components: {
    Badge,
  },
  computed: {
    ...mapState(["language"]),
    translatedExperiences() {
      return this.language
        ? translations.pt.experiences
        : translations.en.experiences;
    },
    description() {
      return this.language
        ? "Responsabilidades e desafios que já agarrei!"
        : "Responsibilities and challenges I have embraced!";
    },
    title() {
      return this.language ? "Minha experiência" : "My experience";
    },
  },
};
</script>

<style lang="scss" scoped>
#background-video {
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  position: fixed;
  left: 0;
  right: 0;
  top: -290px;
  z-index: -1;
  opacity: 0.06;
}

h1 {
  background: linear-gradient(180deg, #fff 0%, rgba(255, 255, 255, 0.7) 100%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  -webkit-text-fill-color: transparent;
  display: block;
  font-size: 2rem;
  margin-top: 130px;
}

h4 {
  color: #efedfdb3;
  margin: 12px auto 0;
  max-width: 455px;
  text-align: center;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 18px;
}

.timeline__header-card {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.solution-image {
  width: 1rem;
  height: 1rem;
}

.cma-image {
  width: 2.5rem;
  height: 1rem;
}

img {
  width: 1.5rem;
  height: 1.5rem;
}

.experience-container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.experience-content {
  overflow-y: scroll;
  width: 50%;
  display: flex;
  height: 80vh;
  flex-wrap: wrap;
  gap: 16px;
  justify-content: center;
  padding: 16px;
  padding-bottom: 0;
  padding-top: 30px;
}

.badges-container {
  display: flex;
  align-items: center;
  gap: 6px;
  margin-top: 16px;
  flex-wrap: wrap;
}

@media screen and (max-width: 1024px) {
  .experience-content {
    width: 95%;
  }
  h2 {
    font-size: 14px !important;
  }

  p {
    font-size: 14px !important;
  }
  .badges-container {
    flex-wrap: wrap;
  }

  .timeline__content {
    margin-left: 4em !important;
    padding: 1.2em;
  }

  .timeline__header-card {
    h2 {
      margin-right: 10px;
    }
  }
}

$bp1: 45em;
h6 {
  margin-top: 2px;
  margin-bottom: 16px;
  color: rgb(174, 156, 255);
}
.timeline {
  position: relative;
  margin: 0;
  padding: 0;
  list-style: none;

  &:before {
    content: "";
    position: absolute;
    top: -3em;
    left: 2em;
    width: 0.15em;
    height: calc(100% + 6em);
    background: rgba(190, 181, 228, 0.342);

    @media screen and (min-width: $bp1) {
      left: 50%;
      transform: translateX(-50%);
    }
  }
}

.timeline__entry {
  position: relative;
  margin-bottom: 2em;
  color: #fff;

  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

.timeline__id {
  position: absolute;
  top: 1.5em;
  left: 2em;
  padding: 0.4em 0.4em;
  background: rgb(146, 93, 199);
  transform: translateX(-50%);
  border-radius: 50%;

  @media screen and (min-width: $bp1) {
    left: 50%;
    transform: translateX(-50%);
  }
}

.timeline__content {
  position: relative;
  display: block;
  margin-left: 6em;
  padding: 1.5em;
  background: hsla(248, 42%, 25%, 0.178);
  border: 1px solid rgba(164, 143, 255, 0.1215686275);
  animation: shimmer 2s infinite;
  border-radius: 14px;

  @media screen and (min-width: $bp1) {
    margin-left: 0;
    width: calc(50% - 4em);
  }

  &:before {
    content: "";
    position: absolute;
    display: block;
    top: 1em;
    left: -1em;
    border-top: 1em solid transparent;
    border-bottom: 1em solid transparent;
    border-right: 1em solid rgba(164, 143, 255, 0.1215686275);

    @media screen and (min-width: $bp1) {
      left: auto;
      right: -1em;
      border-left: 1em solid rgba(164, 143, 255, 0.1215686275);
      border-right: none;
    }
  }
}

.timeline__content--flipped {
  @media screen and (min-width: $bp1) {
    float: right;

    &:before {
      left: -1em;
      right: auto;
      border-left: none;
      border-right: 1em solid rgba(164, 143, 255, 0.1215686275);
    }
  }
}

.timeline__heading {
  margin-bottom: 0.25em;
  font-size: 1rem;
}

.timeline__text {
  color: #ccc;
  font-size: 16px;

  &:last-child {
    margin-bottom: 0;
  }
}

.container {
  width: 85%;
  max-width: 1200px;
  margin: 4em auto;
}

.section-title {
  padding-bottom: 2em;
  text-align: center;
  color: #dddddd;
}

@media screen and (max-width: 768px) {
  h1 {
    font-size: 22px;
    margin-top: 90px;
  }
  h4 {
    font-size: 14px !important;
  }
}
</style>
