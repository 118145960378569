<template>
	<div class="avatar__container">
		<img
			src="https://github.com/hosanabarcelos.png"
			:alt="alt"
		/>
		<!-- <div class="avatar__circle" /> -->
	</div>
</template>

<script>
export default {
	props: {
		filename: {
			type: String,
			default: '',
		},
		alt: {
			type: String,
			default: '',
		}
	}
}
</script>

<style scoped lang="scss">
@import '../assets/styles/variables.scss';
.avatar {
	&__container {
		width: 145px;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 30px;
		background: linear-gradient(to bottom right,
			hsl(240, 1%, 14%) 3%,
			hsl(0, 0%, 19%) 97%
		);

		img {
			width: 7rem;
			height: 7rem;
			border-radius: 50%;
			display: block;
            margin-block-start: 1em;
            margin-block-end: 1em;
            margin-inline-start: 40px;
            margin-inline-end: 40px;
		}
	}

	&__circle {
		position: absolute;
		top: 146px;
		left: 186px;
		width: 1.3rem;
		height: 1.3rem;
		border-radius: 50%;
		z-index: 2;
		background: $green-color;
	}
}

@media screen and (max-width: 1280px) {
	.avatar {
	&__container {
		width: 135px;

		img {
			width: 6rem;
			height: 6rem;
		}
	}

	&__circle {
		top: 109px;
		left: 209px;
		width: 1rem;
		height: 1rem;
	}
}
}
</style>