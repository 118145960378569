<template>
	<aside class="sidebar__container">
		<div class="sidebar__nocontent" v-if="noContent">
			<slot />
		</div>
		<div v-else class="sidebar__info">
			<avatar
				alt="Hosana Barcelos"
			/>

			<div class="sidebar__info-content">
				<h1
					class="sidebar__title"
					title="Hosana Barcelos"
				>
					Hosana Barcelos
				</h1>
				<badge
					text="Software Developer"
				/>
			</div>

			<div class="sidebar__separator" />
		</div>
		<div v-if="!noContent" class="sidebar__contacts">
			<contact-item
				iconName="logo-linkedin"
				title="linkedin"
				text="Hosana Barcelos"
				url="https://www.linkedin.com/in/hosana-barcelos-8206731a1/"
			/>
			<contact-item
				iconName="logo-github"
				title="github"
				text="@hosanabarcelos"
				url="https://github.com/hosanabarcelos"
			/>
            <contact-item
				iconName="logo-gitlab"
				title="gitlab"
				text="@hosanabarcelos"
				url="https://gitlab.com/hosanabarcelos"
			/>
			<contact-item
				iconName="send"
				title="telegram"
				text="@hosanabarcelos"
				url="https://t.me/hosanabarcelos"
			/>
			<contact-item
				iconName="logo-medium"
				title="medium"
				text="@hosanabarcelos"
				url="https://medium.com/@hosanabarcelos"
			/>
            <contact-item-custom
                behance
				url="https://www.behance.net/hosanabarcelos"
			/>
			<contact-item-custom
                spotify
				url="https://open.spotify.com/user/22ccfwaooxerys62gztptlriy?si=dcffda9dd9db4849"
			/>
		</div>
		<div v-if="!noContent" class="sidebar__social-links">
			<!-- <a
				href="https://github.com/hosanabarcelos"
				target="_blank"
			>
				<ion-icon name="logo-github" />
			</a> -->
			<!-- <a
				href="https://www.linkedin.com/in/hosana-barcelos-8206731a1/"
				target="_blank"
			>
				<ion-icon name="logo-linkedin" />
			</a> -->
<!--             <a
				href="https://medium.com/@hosanabarcelos"
				target="_blank"
			>
				<ion-icon name="logo-medium" />
			</a> -->
			<a
				href="https://www.behance.net/hosanabarcelos"
				target="_blank"
			>
				<svg
					id="Calque_1"
					xmlns="http://www.w3.org/2000/svg"
					viewBox="0 0 2500 2500"
					fill="#1E1E1F"
					width="22px"
					height="22px"
					>
					<circle class="st0" cx="1250" cy="1250" r="1250" fill="#c2c2c2"/>
					<path class="st1" d="M883.1 683.6c53.9 0 103.1 4.8 147.6 14.3s82.5 25.1 114.2 46.8c31.7 21.7 56.3 50.5 73.8 86.5s26.2 80.4 26.2 133.3c0 57.1-13 104.7-38.9 142.8-25.9 38.1-64.3 69.3-115 93.6 69.8 20.1 121.9 55.3 156.3 105.5 34.4 50.2 51.6 110.8 51.6 181.7 0 57.1-11.1 106.6-33.3 148.4s-52.1 75.9-89.7 102.3c-37.6 26.5-80.4 46-128.5 58.7-48.4 12.7-98.3 19.1-148.3 19H350V683.6h533.1zm-31.7 458.5c44.4 0 80.9-10.6 109.5-31.7 28.6-21.2 42.9-55.5 42.8-103.1 0-26.4-4.8-48.1-14.3-65s-22.2-30.2-38.1-39.7c-15.9-9.5-34.1-16.1-54.7-19.8-21.2-3.7-42.7-5.6-64.3-5.5H599.2v265l252.2-.2zm14.3 480.7c23.5.1 46.9-2.3 69.8-7.1 22.2-4.8 41.8-12.7 58.7-23.8 16.9-11.1 30.4-26.2 40.5-45.2 10-19 15.1-43.4 15.1-73 0-58.1-16.4-99.7-49.2-124.5-32.8-24.9-76.2-37.3-130.1-37.3H599.2v311l266.5-.1zm746.6-23.8c33.8 32.8 82.5 49.2 146 49.2 45.5 0 84.6-11.4 117.4-34.1 32.8-22.7 52.9-46.8 60.3-72.2h198.3c-31.7 98.4-80.4 168.7-146 211-65.6 42.3-144.9 63.5-238 63.5-64.5 0-122.7-10.3-174.5-30.9-51.8-20.6-95.7-50-131.7-88.1-36-38.1-63.8-83.5-83.3-136.4-19.5-52.9-29.3-111.1-29.3-174.5 0-61.3 10-118.4 30.1-171.3 20.1-52.9 48.7-98.6 85.7-137.2 37-38.6 81.2-69 132.5-91.2 51.3-22.2 108.2-33.3 170.6-33.3 69.8 0 130.6 13.5 182.5 40.5 51.8 27 94.4 63.2 127.7 108.7s57.4 97.3 72.2 155.5c14.8 58.2 20.1 119 15.9 182.5h-591.8c3.1 72.7 21.6 125.6 55.4 158.3zm254.7-431.5c-27-29.6-68-44.4-123-44.4-36 0-65.9 6.1-89.6 18.2-23.8 12.2-42.8 27.2-57.1 45.2-14.3 18-24.3 37-30.1 57.1-5.8 20.1-9.3 38.1-10.3 53.9h366.5c-10.7-57-29.5-100.4-56.4-130zm-348.7-426.4h457.9v127h-457.9v-127z"/>
				</svg>
			</a>
			<a
			href="https://open.spotify.com/user/22ccfwaooxerys62gztptlriy?si=dcffda9dd9db4849"
			target="_blank"
			>
				<svg
				fill="#c2c2c2"
				width="25px"
				height="25px"
				viewBox="0 0 24 24"
				xmlns="http://www.w3.org/2000/svg"
				>
				<path d="M17.9 10.9C14.7 9 9.35 8.8 6.3 9.75C5.8 9.9 5.3 9.6 5.15 9.15C5 8.65 5.3 8.15 5.75 8C9.3 6.95 15.15 7.15 18.85 9.35C19.3 9.6 19.45 10.2 19.2 10.65C18.95 11 18.35 11.15 17.9 10.9ZM17.8 13.7C17.55 14.05 17.1 14.2 16.75 13.95C14.05 12.3 9.95 11.8 6.8 12.8C6.4 12.9 5.95 12.7 5.85 12.3C5.75 11.9 5.95 11.45 6.35 11.35C10 10.25 14.5 10.8 17.6 12.7C17.9 12.85 18.05 13.35 17.8 13.7ZM16.6 16.45C16.4 16.75 16.05 16.85 15.75 16.65C13.4 15.2 10.45 14.9 6.95 15.7C6.6 15.8 6.3 15.55 6.2 15.25C6.1 14.9 6.35 14.6 6.65 14.5C10.45 13.65 13.75 14 16.35 15.6C16.7 15.75 16.75 16.15 16.6 16.45ZM12 2C10.6868 2 9.38642 2.25866 8.17317 2.7612C6.95991 3.26375 5.85752 4.00035 4.92893 4.92893C3.05357 6.8043 2 9.34784 2 12C2 14.6522 3.05357 17.1957 4.92893 19.0711C5.85752 19.9997 6.95991 20.7362 8.17317 21.2388C9.38642 21.7413 10.6868 22 12 22C14.6522 22 17.1957 20.9464 19.0711 19.0711C20.9464 17.1957 22 14.6522 22 12C22 10.6868 21.7413 9.38642 21.2388 8.17317C20.7362 6.95991 19.9997 5.85752 19.0711 4.92893C18.1425 4.00035 17.0401 3.26375 15.8268 2.7612C14.6136 2.25866 13.3132 2 12 2Z" fill="#C2C2C2"/>
				</svg>
			</a>
			<a
				href="https://unsplash.com/pt-br/@hosanabarcelos"
				target="_blank"
			>
				<svg
					fill="#c2c2c2"
					width="20px"
					height="20px"
					viewBox="0 0 24 24"
					role="img"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path d="M7.5 6.75V0h9v6.75h-9zm9 3.75H24V24H0V10.5h7.5v6.75h9V10.5z"/>
				</svg>
			</a>
            <a
				href="mailto:contato.hosanabarcelos@gmail.com"
				target="_blank"
			>
            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="25" height="25" viewBox="0 0 64 64" fill="#c2c2c2">
            <path d="M55,10H9c-2.209,0-4,1.791-4,4v34c0,2.209,1.791,4,4,4h46c2.209,0,4-1.791,4-4V14C59,11.791,57.209,10,55,10z M55,45.738 C54.954,47,53.901,48,52.618,48H49.5L51,21L32,33L13,22l1.5,26h-3.118C10.099,48,9.046,47,9,45.738V16.289 c0-1.25,1.03-2.263,2.3-2.263h1.2L32,29l19.5-14.974h1.2c1.27,0,2.3,1.013,2.3,2.263V45.738z"></path>
            </svg>
			</a>
		</div>
	</aside>
</template>

<script>
import Avatar from './Avatar.vue';
import Badge from './Badge.vue';
import ContactItem from './ContactItem.vue';

export default {
	props: {
		noContent: {
		   type: Boolean,
		   default: false,
		}
	},
	components: {
		Avatar,
		Badge,
		ContactItem,
	}
}
</script>

<style scoped lang="scss">
@import '../assets/styles/variables.scss';

.sidebar {
	&__container {
		position: sticky;
		top: 60px;
		max-height: max-content;
		margin: 20px;
        margin-top: 0;
		padding: 40px;
		z-index: 1;
        min-width: 21rem;
		width: auto;
		background: $container-bg;
		border: 1px solid hsl(0, 0%, 22%);
		border-radius: 20px;
	}

	&__nocontent {
        overflow: auto;
        max-height: 635px;
        height: 100vh;
	}

	&__info {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		gap: 20px;

		&-content {
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
			margin-top: 28px;
			color: $white-0;

			h1 {
				margin-bottom: 10px;
			}
		}
	}

	&__separator {
		width: 100%;
		height: 1px;
		background: hsl(0, 0%, 22%);
		margin: 16px 0;
	}

	&__social-links {
		width: max-content;
		display: flex;
		margin: 0 auto;
		margin-top: 2rem;
		gap: .6rem;

		ion-icon {
			color: $white-30;
			font-size: 1.7rem;

			&:hover {
				color: $white-0;
			}
		}

		svg {
			width: 1.8rem;

			&:hover path {
				fill: $white-0;
			}
		}
	}
}


@media screen and (max-width: 1280px) {
	.sidebar {
	&__container {
        position: relative;
		background: transparent;
		border: 0;
		padding: 20px;
        backdrop-filter: blur(0px);
        margin-bottom: -30px;
	}

    &__contacts {
        display: flex;
        gap: .5rem;
    }

    &__social-links {
        display: none;
    }

    &__info {
        gap: 10px;
    }

    &__nocontent {
        overflow: visible;
        max-height: max-content;
        padding: 10px;
        height: 100%;
    }
}
}

@media screen and (max-width: 300px) {
    .sidebar {
	&__contacts {
        display: grid;
        grid-template-columns: 50px 50px 50px 50px;
        gap: 0;
        justify-content: center;
        justify-items: center;
	    }
        &__container {
            padding: 0px;
        }
    }
}


</style>