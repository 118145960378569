<template>
    <main>
        <!-- <div class="moon">
        <img src="../assets/images/moon.svg" />
        </div> -->
        <div class="main__container">
            <!-- <init-page /> -->
            <!-- <Sidebar />
            <Container />
            <Sidebar noContent class="nocontent">
                <div class="header">
                    <h2 v-if="language">Oi, eu sou Hosana.</h2>
                    <h2 v-else>Hi, i'm Hosana.</h2>
                        <div class="switch">
                        <input id="language-toggle" class="check-toggle check-toggle-round-flat" type="checkbox" v-model="language" @click="switchLanguage">
                        <label for="language-toggle"></label>
                        <span class="on">US</span>
                        <span class="off">BR</span>
                    </div>
                </div>
                <div v-if="language">
                    <p>Formada no curso Bacharelado de Sistemas de Informação pela Universidade Unigranrio, com experiência em Desenvolvimento de Software.
                    Sou uma desenvolvedora de software focada em desenvolvimento web e prezo por boas documentações. Apesar de já ter explorado diversas tecnologias, atualmente trabalho desenvolvendo projetos com frameworks JavaScript.</p>
                        <br>
                    <p>Já trabalhei na web com foco em criação de landing pages, desde a prototipação até o deploy. Hoje, tenho 2+ anos de experiência trabalhando com desenvolvimento de software e priorizando entregas de qualidade.</p>
                        <br>
                    <p>Você pode encontrar artigos diversos que escrevo no meu <a href="https://medium.com/@hosanabarcelos" target="_blank">Medium</a> se é interessado em leitura.</p>
                    <p>E, se você estiver interessado em ver meus projetos, poderá vê-los no meu <a href="https://github.com/hosanabarcelos" target="_blank">GitHub</a>.</p>
                        <br>
                    <p>Fun fact: Sou viciada em música e amante de café. Me interesso por assuntos psíquicos, astronomia e escrevo coisas.</p>
                </div>
                <div v-else>
                    <p>Graduated in the Bachelor of Information Systems course at Unigranrio University, with experience in Software Development.
                        I'm a software developer focused on web development and I value good documentation. Despite having already explored several technologies, currently work developing projects with JavaScript frameworks.</p>
                        <br>
                    <p>I've worked on the web with a focus on creating landing pages, from prototyping to deployment. Today, I have 2+ years of experience working with software development and prioritizing quality deliveries.</p>
                        <br>
                    <p>You can find miscellaneous articles I write on my <a href="https://medium.com/@hosanabarcelos" target="_blank">Medium</a> if you are interested in reading.</p>
                    <p>And, if you're interested in seeing my projects, you can see them on my <a href="https://github.com/hosanabarcelos" target="_blank">GitHub</a>.</p>
                        <br>
                    <p>Fun fact: I'm addicted to music and coffee lover. I am interested in psychic subjects, astronomy and I write things.</p>
                </div>

                <div class="sidebar__separator" />

                comentaaaaaaaaaaaaar


            <div class="carousel__container">
                <carousel
                    class="carousel"
                    :items-to-show="1"
                    :wrapAround="true"
                    :pauseAutoplayOnHover="true"

                >
                    <slide v-for="recomendation in recomendations" :key="recomendation">
                        <div class="carousel__items">
                            <div class="carousel__persona">
                                <img :src="recomendation.avatar" alt="">
                                <h3>{{ recomendation.name}}<br>
                                <span>{{ recomendation.job}}</span></h3>
                            </div>
                            <h4>{{ recomendation.feedback }}</h4>
                        </div>
                    </slide>
                    <template #addons>
                    <navigation />
                    <pagination />
                    </template>
                </carousel>
            </div>

            <div class="sidebar__vertical-separator" />

            <div>
            <h2 v-if="language">Vamos construir algo juntos?</h2>
            <h2 v-else>Shall we build something together?</h2>
                <p v-if="language">Se você tem algum projeto em mente, ou apenas quer bater um papo, sinta-se à vontade para me enviar uma mensagem.</p>
                <p v-if="!language">If you have a project in mind, or just want to chat, feel free to send me a message.</p><br>
                <primary-button
                    :text="language ? 'Fale comigo' : 'Talk to me'"
                    path="https://t.me/hosanabarcelos"
                >
                    <ion-icon name="send-outline"></ion-icon>
                </primary-button>
            </div>

            <PrimaryFooter class="footer__mobile" />
            </Sidebar> -->
        </div>
    </main>
    <!-- <PrimaryFooter class="footer__desk"/> -->
</template>

<script>
import Sidebar from '../components/Sidebar.vue';
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel'
import { recomendations } from '../utils/recomendations.js'
import PrimaryButton from '../components/PrimaryButton.vue';
import PrimaryFooter from '@/components/PrimaryFooter.vue';
import InitPage from './InitPage.vue';
import AboutPage from './AboutPage.vue';
import Navbar from '@/components/Navbar.vue';
// import Container from '../components/Container.vue';

export default {
    data() {
        return {
            recomendations,
            language: false,
        }
    },
    components: {
        Sidebar,
        Carousel,
        Slide,
        Pagination,
        Navigation,
        PrimaryButton,
        PrimaryFooter,
        InitPage,
        AboutPage,
        Navbar
    },
    methods: {
        switchLanguage() {
            return this.language = true;
        }
    },
}
</script>

<style scoped lang="scss">
@import '../assets/styles/variables.scss';


</style>
