import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import './assets/styles/global.scss';
import { inject } from '@vercel/analytics';
import { PhosphorIcons } from "@phosphor-icons/vue";
import store from './store';

import Vue3Lottie from 'vue3-lottie';


const app = createApp(App);

app.use(router);
app.use(Vue3Lottie);
app.use(store);

app.component('ph-icon', PhosphorIcons);

inject();

app.mount('#app');
