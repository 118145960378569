<template>
	<div class="contact-item">
        <a :href="url" target="_blank">
            <div class="contact-item__icon">
                    <slot />
            </div>
        </a>
	</div>
</template>

<script>
import { PhHorse, PhHeart, PhCube } from "@phosphor-icons/vue";

export default {
    components: {
        PhHeart
    },
	props: {
		iconName: {
			type: String,
			default: ''
		},
		title: {
			type: String,
			default: ''
		},
		text: {
			type: String,
			default: ''
		},
		url: {
			type: String,
			default: ''
		},
        behance: {
            type: Boolean,
            default: false
        },
        spotify: {
            type: Boolean,
            default: false
        }
	}
}
</script>

<style scoped lang="scss">
@import '../assets/styles/variables.scss';

.contact-item {
	display: flex;
	align-items: center;

	&__icon {
        align-items: center;
        -webkit-backdrop-filter: blur(6px);
        backdrop-filter: blur(6px);
        border-radius: 12px;
        border: 1px solid #a48fff1f;
        display: flex;
        margin: 0 auto 12px;
        padding: 12px 14px 12px 15px;
        position: relative;
        width: -moz-max-content;
        width: max-content;

        &:hover {
            box-shadow: inset 0 0 11px 11px #a48fff1f;
        }
    }
}

a {
    border: none !important;
}

@media screen and (max-width: 430px) {
    .contact-item {

	&__icon {
        padding: 10px;
    }
}
}
</style>