import { createStore } from 'vuex';

export default createStore({
    state: {
        language: false,
      },
      mutations: {
        setLanguage(state, newLanguage) {
          state.language = newLanguage;
        },
      },
      getters: {
        language(state) {
          console.log('Getter chamado com estado:', state.language);
          return state.language;
        },
      },
});