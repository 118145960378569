<template>
    <div class="footer__container">
	    <p>
		  Hosana Barcelos © {{ CurrentYear }}.
        </p>
    </div>
</template>

<script>
export default {
    data() {
        return {
            CurrentYear: null
        }
    },
	props: {
		text: {
			type: String,
			default: ''
		},
	},
    mounted() {
        this.getCurrentYear();
    },
    methods: {
        getCurrentYear() {
            this.CurrentYear = new Date().getFullYear();
        }
    }
}
</script>

<style scoped lang="scss">
@import '../assets/styles/variables.scss';
.footer {
	&__container {
        color: #F6F6F6;
        position: absolute;
        bottom: 0;
        text-align: center;
        width: 100%;
        background: radial-gradient(71.86% 50% at 50% 0%,rgba(168,127,255,.04) 0%,rgba(168,127,255,0) 100%),rgba(4,1,21,.1);
        backdrop-filter: blur(15px);
        font-size: .9rem;
        padding: 12px;
        border-top: 2px solid #a48fff1f;
    }
}
</style>